import React, {Component} from 'react';
import Logo from '../img/logoRis.png';
import {Link} from "react-router-dom";
import {subTotal} from '../../util/functions.js'
class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };

  }

  componentDidUpdate(propsPrecedenti) {
    // Utilizzo tipico (non dimenticarti di comparare le props):

  }

  componentDidMount() {
    var elem = document.getElementById("caricamento");
    console.log('elementooo');
    console.log(elem);
    if(elem){
        elem.remove();
    }


  }

  render() {
    let {item,carts,home} = this.props
    return (<div className='barra'>
      <div className='specialBar'></div>
      <div style={{
          background: 'transparent',
          position: 'relative',
          zIndex: 1,
          display: 'flex'
        }} className='containerFix'>
        <Link to="/">

            {/*<h1 style={{color:'white',fontSize:30}}>Pizzarella</h1>*/}
            {<div style={{background:'var(--color-primary)'}}><img style={{
                width: 100,
                padding: 10,
                borderRadius: 66
              }} src={Logo} alt='logo_ristorante'/></div>}
        </Link>
        </div>
      {home ? (
        <div>
        <div className='container restaurant'>
          <div style={item && item.img
              ? {
                backgroundImage: 'url(' + item.img + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }
              : {}} className='containerFix grid02'>

            <div style={{
                padding: 10
              }}></div>
          </div>
        </div>
        {/*<div className='containerFix' style={{
            background: 'transparent',
            padding: 10,
            marginTop: 10
          }}>

          <h2 style={{
              marginTop: 0,
              color: '#828585'
            }}>{
              item
                ? item.nome
                : null
            }</h2>
          <p style={{
              marginTop: 10,
              color: '#828585',
              fontSize: 16
            }}>{
              item
                ? item.desc
                : null
            }</p>
          <div className='boxService' style={{
              display: 'flex'
            }}>
            {

              item && item.take_away
                ? <div onClick={() => this.props.actionButton()} style={{
                      background: carts && carts.type == 'take_away'
                        ? '#d6d6d6'
                        : null,
                      opacity: carts && carts.type == 'take_away'
                        ? 1
                        : 0.7
                    }}>
                    <p style={{
                        color: '#828585',
                        fontSize: 20
                      }}><img style={{
                      height: 40
                    }} src="https://img.icons8.com/cotton/100/000000/take-away-food.png"/></p>
                    <p>Asporto</p>
                  </div>
                : null
            }
            {
              item && item.delivery
                ? <div onClick={() => this.props.actionButton()} style={{
                      background: carts && carts.type == 'delivery'
                        ? '#d6d6d6'
                        : null,
                      opacity: carts && carts.type == 'delivery'
                        ? 1
                        : 0.7
                    }}>
                    <p style={{
                        color: '#828585',
                        fontSize: 20
                      }}><img style={{
                      height: 40
                    }} src="https://img.icons8.com/cotton/64/000000/delivery-scooter.png"/></p>
                    <p>Delivery</p>
                  </div>
                : null
            }

          </div>
        </div>
      */}</div>
      ) : null}


      <div></div>
    </div>)
  }
}

//const condition = authUser => !!authUser;

export default Footer
